<template>
    <div id="pageTable">        
        <v-container xl fluid>
            <v-row>
                 <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaCartas"
                        :tableName="'Cartas'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                        :isSelectable="true"
                        :unselected="unselected_general"
                        @selectAllEvent="selectAll"
                    >
                        <template slot="actionButtonsLeft">
                            <!--<div class="botonAgregar">
                                    <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    :disabled="!masivo"
                                    @click="abrirModalRenovar()">
                                        <v-icon size="20" dark>refresh</v-icon>Renovar
                                    </v-btn>
                                </div>-->
                                <div class="botonAgregar">
                                    <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    :disabled="!masivo"
                                    @click="descargarMasivo()">
                                        <v-icon size="20" dark>get_app</v-icon>Descargar
                                    </v-btn>
                                </div>
                        </template>

                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" :sm="rol == 'admin-empresa'?'12':'6'" :md="rol == 'admin-empresa'?'12':'6'" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                outlined
                                label="Nombre"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo Contrato"
                                    :items="tipoContratos"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="tipo_contrato_value"
                                    class="pa-0 ma-0"
                                    id="txtTipoContrato"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="contratos_estatus"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estatus"
                                    persistent-hint
                                    v-model="contrato_estatus_value"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        
                        <tbody slot="body" slot-scope="{ data }">
                            <template v-for="(item,index) in data">
                                <expandable-item 
                                    :item="item"
                                    :selected_general="selected_general" 
                                    :key="index" 
                                    :full="false"
                                    @abrirModalPDF="abrirModalPDF" 
                                    @descargar="descargar" 
                                    @abrirModalRenovar="abrirModalRenovar"
                                    @abrirModalCargaDocumento="abrirModalCargaDocumento"
                                    @selectEmployee="selectEmployee">
                                </expandable-item>
                                
                            </template>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialogRenovar" scrollable persistent width="600px" class="borde-card">
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
                                        <ValidationProvider v-slot="{ errors }" name="Tipo Contrato" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Tipo Contrato"
                                                :items="tipoContratos"
                                                item-text="claveDescripcion"
                                                item-value="id"
                                                persistent-hint
                                                v-model="renovacion.tipo_contrato_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors" 
                                                id="txtTipoContrato"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="determinado" xs="12" sm="12" md="12" class="pa-0 ma-0 mt-3 radio_group_01 radio-contrato" >
                                        <v-radio-group v-model="renovacion.dias">
                                            <template v-slot:label>
                                                <div class="mb-4">Elegir nueva vigencia:</div>
                                            </template>
                                            <v-radio class="mb-4 radio-label" label="30 días" value="30"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="60 días" value="60"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="90 días" value="90"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                   @click="masivo?renovarContratoMasivo():renovarContrato()" 
                                   :disabled="invalid" 
                                   :loading="isSaving"
                                >
                                    Aceptar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialog_pdf" persistent width="720px">
                    <v-card>
                        <v-card-title>
                            <div class="col-lg-12 pa-0">
                                <v-btn
                                    @click="cerrarModal()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            </div>
                            <span class="titleModal ml-4" v-text="titulo_modal"></span>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                            <v-container grid-list-md>
                                <v-row>
                                    <div id="padre" v-if="!procesoFinalizado" class="ma-5">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    <template>
                                        <v-col v-show="procesoFinalizado" cols="12"  xs="12" sm="12" md="12">
                                            <iframe src="" id="myIframe"  width='100%' height='460px' allowfullscreen webkitallowfullscreen></iframe>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
              <template>
            <v-dialog v-model="dialogCargaDocumento" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalCargaDocumento"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <h2 class="titleModal">{{ titulo_modal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md id="contenedor" class="pt-0">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                        <ValidationProvider v-slot="{ errors }" name="Documento" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivo.archivo"
                                                accept=".pdf"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-layout row>
                                <v-flex class="justify-center">
                                    <div class="btnModal">
                                        <v-btn class="btnGuardar modal-pull-right" @click="guardarDocumento()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                        <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
        </v-container>
    </div>
</template>

<script>

//IMPORTS

//APIS

import apiTipoContratoSAT from '@/api/nominas/tipoContrato';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import queries from '@/queries/Contratos';
import contratosApi from "@/api/contratos";
import documentoApi from "../api/documentos";

//COMPONENTS

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemContrats.vue';

//PLUGINS

import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
        ExpandableItem,
    },
    data() {
        return {
            accion                  : null,
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,
            empresa_value           : null,
            sucursal_value          : null,
            nombre                  : null,
            tipo_contrato_value     : null,
            contrato_estatus_value  : null,

            dialog_pdf              : false,
            dialogCargaDocumento    : false,
            dialogRenovar           : false,
            procesoFinalizado       : false,
            isSaving                : false,
            overlay                 : false,
            masivo                  : false,
            checkAll                : false,
            determinado             : false,

            titulo_modal    : "",
            url             : "contratos/find",
            imagen          : "/static/modal/contrato.png",
            fraseOverlay    : "Descargando",

            clientes                : [],
            empresas                : [],
            sucursales              : [],
            departamentos           : [],
            registroPatronal        : [],
            tipoContratos           : [],
            selected_general        : [],
            unselected_general      : [],
            contratos_estatus       : [
                {
                    nombre: "Listo para firmar",
                    id: 1
                },
                {
                    nombre: "Incompleto",
                    id: 2
                },
                {
                    nombre: "Firmado",
                    id: 3
                },
                {
                    nombre: "Pendiente por renovar",
                    id: 4
                },
                {
                    nombre: "Vencido",
                    id: 5
                },
                {
                    nombre: "Cancelado",
                    id: 6
                }
            ],
            columns                 : [
                {
                    label: "Empleado",
                    name: "empleado",
                    filterable: true,
                },
                {
                    label: "Tipo de carta",
                    name: "tipo_contrato",
                    filterable: true,
                },
                {
                    label: "Vigencia",
                    name: "vigencia",
                    filterable: true,
                },
                {
                    label: "Estatus",
                    name: "estatus",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: 'center',
                    filterable: false,
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    width:"8",
                    filterable: false,
                },
            ],

            filters         : {
            },
            renovacion      : {
                value : null,
                contrato_empleado_id : null,
                tipo_contrato_id : null,
                registro_patronal_id : null,
            },
            archivo         : {
                archivo                 : [],
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                departamento_id         : null,
                empleado_id             : null,
                documento_id            : null,
                url                     : null,
                extension               : null,
                nombre                  : null,
                contrato_empleado_id    : null
            }
        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Activa o desactivo los botones de 
                       accion masiva*/
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresa_value: function(val) {  
            this.cambiaEmpresa(val);
        },
        /*Descripcion: Muestra o quita los radio butons de dias
                       segun el tipo de contrato*/
        'renovacion.tipo_contrato_id' : function(val){
            let contrato = this.tipoContratos.find(element => element.id == val);
            if(contrato.tipo_contrato.clave == "03"){
                this.determinado = true;
                this.renovacion.dias = "30";
            }else{
                this.determinado = false;
                this.renovacion.dias = null;
            }
        }
    },
    
    methods: {
        //FUNCIONES GENERALES

        /*
        Entrada:
        Salida:
        Descripción: Se ejectua desde el crate para poder ver 
                     el tipo del rol del usuario loguado y en base a eso 
                     realizar validaciones por rol ademas de ejecutar las 
                     funcinones para el funcionamiento inicial del modulo.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
            }

            this.filters.tipo = "CARTAS";

            if (this.rol == "admin") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
            }

            if (this.rol == "admin-empresa") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
            }

            if(this.rol == "admin-sucursal"){
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

            //this.getTiposContratosSAT();
        },

        /*
        Entrada: isSelected: Boleano pasando el valor del check todos de la tabla.
                 items: Array de ids de empleados para llenar los checbox seleccionado
        Salida:
        Descripción: Se asigna eñ valor del check masivo para validar en
                     este modulo y nos da los items de los empleados si 
                     o no seleccionados.
        */
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },

        /*
        Entrada: id: id del empledo seleccionado
        Salida:
        Descripción: Agrega o quita del array de empleados seleccionados
                     segun el id mandado y agrega a los no seeccionado en el 
                     caso del check masivo sea verdadero.
        */
        selectEmployee(id){
            if(this.selected_general.includes(id)){
                let index = this.selected_general.indexOf(id);
                this.selected_general.splice(index, 1);
            }else{
               this.selected_general.push(id); 
            }

            if(this.checkAll){
                if(this.unselected_general.includes(id)){
                    let index = this.unselected_general.indexOf(id);
                    this.unselected_general.splice(index, 1);
                }else{
                    this.unselected_general.push(id); 
                }
            }

            //console.log("sel",this.selected_general)
            //console.log("unsel",this.unselected_general)
        },

        /*
        Entrada:
        Salida:
        Descripción: Abre el explorador de archivos del 
                     navegador para seleccionar un archivo 
                     y subirlo como contrato firmado.
        */
        open(){
            document.getElementById("fileDocument").click();
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal visualizador de pdf y 
                     hace la peticion al api para traer 
                     el contrato y ser visualizado.
        */
        abrirModalPDF(item){
            let self = this;
            let url = "contratos/descargar";

            this.titulo_modal = "Vista previa";
            this.dialog_pdf = true;

            const axios = require("axios");

            let parametros = { url_archivo: item.url_archivo,contrato_empleado_id:item.id };
            try {
                /*
                Entrada:parametros Objeto {url_archivo,contrato_empleado_id}
                Salida: blob del contrato en pdf
                Descripción: API que puede recibir url_archivo null para 
                             traer el contrato del sistema o un string  
                             para traer el archivo firmado.
                */
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    var fileURL = URL.createObjectURL(response.data);
                    $('#myIframe').attr('src', fileURL); 
                    $('iframe').on("load", function () {
                        $(this).contents().find('img').css({width: '100%'});
                    });
                    self.procesoFinalizado  = true;
                });
            } catch (error) {
                console.log(error);
            }
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal de renovación y asigna 
                     parametros para la renovación.
        */
        abrirModalRenovar(item = null){
            this.dialogRenovar = true;
            this.titulo_modal = 'Renovar contrato';

            if(item){
                this.renovacion.contrato_empleado_id = item.id;
                this.renovacion.registro_patronal_id = item.registro_patronal_id;
                console.log(item)
                this.$apollo.queries.contratosFormatos.skip = false;
            }
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal para subir contratos firmados y 
                     asigna parametros para la subida del documento.
        */
        abrirModalCargaDocumento(item){
            this.dialogCargaDocumento = true;
            this.titulo_modal = 'Cargar contrato firmado';
            
            this.archivo.cliente_id = item.data.cliente_id;
            this.archivo.empresa_id = item.data.empresa_id;
            this.archivo.sucursal_id = item.data.sucursal_id;
            this.archivo.departamento_id = item.data.departamento_id;
            this.archivo.empleado_id = item.data.empleado_id;
            this.archivo.contrato_empleado_id = item.id;  
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de tipos contratos sat para 
                     llenar el select de tipo contrato.
        */
        getTiposContratosSAT(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoContratoSAT.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tipoContratos.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de contratos.");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de clientes para llenar 
                     el select en el filtrado.
        */
        async getClientes() {
        let param = { activo: true, paginate: false };
        await clientesApi
            .getClientes(param)
            .then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
            console.error(err);
            Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Empresas para llenar 
                     el select en el filtrado.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi
                    .getEmpresas(param)
                    .then(response => {
                        this.empresas = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        async cambiaEmpresa(empresa) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi
                .getSucursales(parametros)
                .then(response => {
                    this.sucursales = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                });
        },

        /*
        Entrada:err Array de string
        Salida:
        Descripción: Recibe un array de errores de los apis para 
                     ser mostrados con un toast.
        */
        respuesta_error(err) {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },

        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Prepara los parametros para el filtrado 
                     de la tabla segun se llenen los inputs 
                     en la bisqueda avanzada.
        */
        setFilters(){
            let nombre          = this.nombre;
            let cliente         = this.cliente_value;
            let empresa         = this.empresa_value;
            let sucursal        = this.sucursal_value;
            let tipo_contrato   = this.tipo_contrato_value;
            let estatus          = this.contrato_estatus_value; 
            

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            }

            let filterParams = {
                nombre              : nombre,
                cliente_id          : cliente,
                empresa_id          : empresa,
                sucursal_id         : sucursal,
                tipo_contrato_id    : tipo_contrato,
                estatus_id          : estatus
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                tipo: this.filters.tipo
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;

            this.resetValues();
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        cerrarModal() {
            this.isSaving = false;
            this.dialog_pdf = false; 
            this.dialogRenovar = false;
            this.procesoFinalizado = false;
            this.dialogCargaDocumento = false;
           
            $('#myIframe').attr('src', "");

            this.resetValues();
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables del modulo 
                     segun el rol para dejarlo en estado inicial.
        */
        resetValues() {

            this.$nextTick(() => { 
                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.empresas = [];
                    this.sucursales = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.sucursales = [];
                }

                if (this.rol == "admin-empresa") {
                    this.sucursal_value = null;
                }

                this.nombre = null;
                this.tipo_contrato_value = null;
                this.contrato_estatus_value = null;
               
                this.archivo = {
                    archivo                 : [],
                    cliente_id              : null,
                    empresa_id              : null,
                    sucursal_id             : null,
                    departamento_id         : null,
                    empleado_id             : null,
                    documento_id            : null,
                    url                     : null,
                    extension               : null,
                    nombre                  : null,
                    contrato_empleado_id    : null
                }
            });

        },

        //FUNCIONES ESPECIFICAS
        
        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API renovarContrato() enviando los 
                     parametros tomados del contrato seleccionado
                     y actualiza la tabla para mostrar los cambios.
        */
        renovarContrato(){

            let params = {"contrato_empleado_id": this.renovacion.contrato_empleado_id,
                          "tipo_contrato_id": this.renovacion.tipo_contrato_id,
                          "dias": this.renovacion.dias};
            
            this.isSaving = true;

            contratosApi
                .renovarContrato(params)
                .then((response) => {
                    this.$refs.tablaCartas.getData();
                    this.cerrarModal();
                    Notify.Success("El contrato se renovó satisfactoriamente.");
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API renovarContratoMasivo() enviando los 
                     parametros masivos de renovacion seleccionados
                     y actualiza la tabla para mostrar los cambios.
        */
        renovarContratoMasivo(){

            let params = {  "todos"               : this.checkAll,
                            "paginate"            : false,
                            "deseleccionados"     : this.unselected_general,
                            "seleccionados"       : this.selected_general,
                            "dias"                : this.renovacion.dias,
                            "tipo_contrato_id"    : this.renovacion.tipo_contrato_id,
                            "nombre"              : this.filters.nombre,
                            "cliente_id"          : this.filters.cliente_id,
                            "empresa_id"          : this.filters.empresa_id,
                            "sucursal_id"         : this.filters.sucursal_id,
                            "tipo"                : this.filters.tipo 
                          }
            
            this.isSaving = true;

            contratosApi
                .renovarContratoMasivo(params)
                .then((response) => {
                    this.$refs.tablaCartas.getData();
                    this.cerrarModal();
                    Notify.Success("Los contratos se renovarón satisfactoriamente.");
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api contratos/descargar para traer el 
                     fromato del contrato para poder descargarlo 
                     y frimarlo fisicamente.
        */
        async descargar(itemDescarga = null) {
            const {data:item, nombre_completo} = itemDescarga;
            this.overlay = true;
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "contratos/descargar";
            let parametros = { url_archivo: item.url_archivo,contrato_empleado_id:item.id };


            var nombre_archivo = "";
            let nombreArchivo = "";
            let archivo_extension = "";

            if(item.url_archivo) {
                nombreArchivo = item.url_archivo.split("/");
                archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".")
                nombre_archivo = nombre_completo + " " + item.nombre + "." + archivo_extension[archivo_extension.length - 1];
            }
            else{
                nombre_archivo = nombre_completo + " " + item.nombre + ".pdf";
            }
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    self.overlay = false;
                    FileDownload(response.data, nombre_archivo);
                    
                });
            } catch (error) {
                console.log(error);
                self.overlay = false;
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api contratos/descargar-masivo para traer el 
                     fromato del contrato para poder descargarlo 
                     y frimarlo fisicamente.
        */
        async descargarMasivo(item = null) {
            this.overlay = true;
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "contratos/descargar-masivo";
            let parametros = {  "todos"               : this.checkAll,
                                "paginate"            : false,
                                "deseleccionados"     : this.unselected_general,
                                "seleccionados"       : this.selected_general,
                                "nombre"              : this.filters.nombre,
                                "cliente_id"          : this.filters.cliente_id,
                                "empresa_id"          : this.filters.empresa_id,
                                "sucursal_id"         : this.filters.sucursal_id,
                                "tipo"                : this.filters.tipo 
                            };

            var nombre_archivo = "Cartas.zip";

            if(item){
                nombre_archivo = item.nombre + ".zip";
            }

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros
                }).then(function(response) {
                    if(response.headers['content-type'] == 'fallo')
                        Notify.ErrorToast("No se encontraron todo los archivos solicitados.");
                    
                    self.overlay = false;
                    FileDownload(response.data, nombre_archivo);
                    
                })
                .catch((error) => {
                    self.overlay = false;
                    Notify.ErrorToast("No se encontro el archivo solicitado.");
                });
            } catch (error) {
                console.log(error);
                self.overlay = false;
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Prepara el archivo seleccionado en el modal de subir archivo 
                     para ser enviado ejectutando el API addArchivo() una vez 
                     terminado la carga del archivo se actualiza la url del empleado
                     ejecutando el API updateArchivo() y actualiza la tabla 
                     para mostrar los cambios.
        */
        guardarDocumento() {
            let self = this;
            if (this.archivo.archivo.length == 0) {
                Notify.Warning("ADVERTENCIA", "No se ha seleccinado un archivo");
                return;
            }

            this.isSaving = true;
            let formData = new FormData();
            formData.append("archivo", this.archivo.archivo);
            formData.append("cliente_id", this.archivo.cliente_id.toString());
            formData.append("empresa_id", this.archivo.empresa_id.toString());
            formData.append("sucursal_id", this.archivo.sucursal_id.toString());
            formData.append("departamento_id", this.archivo.departamento_id.toString());
            formData.append("empleado_id", this.archivo.empleado_id.toString());

                documentoApi
                .addArchivo(formData)
                .then((response) => {
                    self.archivo.nombre = response.nombre;
                    self.archivo.extension = response.extension;
                    self.archivo.url = response.url;

                    let params = {"contrato_empleado_id": self.archivo.contrato_empleado_id,
	                              "url_archivo": self.archivo.url};
                    contratosApi
                        .updateArchivo(params)
                        .then((response) => {
                            self.$refs.tablaCartas.getData();
                            this.cerrarModal();
                            Notify.Success("El archivo se guardó satisfactoriamente.");
                        })
                        .catch((err) => {
                            self.respuesta_error(err);
                        });
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
        }
    },
    apollo: {
        contratosFormatos: {
            query       : queries.contratosFormatosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereConditions : {OR: [{column: "REGISTRO_PATRONAL_ID", operator:"IS_NULL"},{AND:[{column:"REGISTRO_PATRONAL_ID",value:this.renovacion.registro_patronal_id}]}]},
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'consecutivo',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                this.tipoContratos = JSON.parse(JSON.stringify(this.contratosFormatos.data));

                this.tipoContratos.map(item => {
                    let rp = item.registro_patronal !== null ? " - "+item.registro_patronal.nombre:""
                    item.claveDescripcion = item.tipo_contrato.clave+" - "+item.nombre+rp;
                });
            },
        },
    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
    mounted(){

    },
    updated() {        
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight   = "4px";
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width   = "50px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(7)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(7)').style.width   = "80px";
    }
    
}
</script>


<style scoped>
    #padre {
        padding-left: 40%;
        height: 200px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -15px;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .cursor-pointer{
      cursor:pointer;
    }
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    iframe{
    border:none;
    }
</style>
